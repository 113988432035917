





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GoToPayment extends Vue {
  private get orderUUID() {
    return this.$route.params.orderUUID
  }

  private mounted() {
    const utm: string[] = Object.keys(this.$route.query).filter(q => q.includes('utm_'))
    const utmQuery: Record<string, any> = {}
    utm.forEach(q => utmQuery[q] = this.$route.query[q])
    this.$router.replace({
      name: 'master.payment.order',
      params: {
        orderUUID: this.orderUUID,
      },
      query: utmQuery,
    })
  }
}
